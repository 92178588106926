import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/meta/space.png'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import { quotes } from '../components/blocks/QuoteBlock'
import QuoteMosaicBlock from '../components/blocks/QuoteMosaicBlock'
import VideoBlock from '../components/blocks/VideoBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/developer-productivity/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight1: file(
      relativePath: { eq: "images/mosaic/transparent-surveys.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft1: file(
      relativePath: { eq: "images/mosaic/initiatives-650-500.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/work-log.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/slack-transparent.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight3: file(relativePath: { eq: "images/mosaic/metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/overview/product-overview-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const SPACEPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="SPACE framework as a platform"
      variant="dark"
      isNew
      description="Swarmia allows you to measure and improve all five elements of the SPACE of developer productivity. Get started with a demo or a free trial."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={
          <>
            The developer productivity platform that aligns with the SPACE
            framework
          </>
        }
        content="Swarmia allows you to measure and improve all five elements of the SPACE of developer productivity."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading={<>Measure and improve all elements of the SPACE framework</>}
        content={
          <>
            The{' '}
            <a href="https://queue.acm.org/detail.cfm?id=3454124">
              SPACE of developer productivity
            </a>{' '}
            is a well-known framework developed by a group of scientists from
            Microsoft Research, GitHub, and the University of Victoria. It
            encourages organizations to measure these five elements of developer
            productivity.
          </>
        }
      />
      <QuoteMosaicBlock
        title={<>Satisfaction & well-being</>}
        content={
          <>
            Use Swarmia’s proven developer experience survey framework to
            systematically improve developer satisfaction and well-being.
            <br />
            <br />
            Swarmia automatically correlates qualitative feedback with related
            system metrics, making it easy to get a holistic picture of
            developer productivity.
          </>
        }
        image={getImage(data.mosaicRight1)!}
        imageAlign="right"
        quote={quotes.dave}
      />
      <QuoteMosaicBlock
        title={<>Performance</>}
        content={
          <>
            The performance of a software team is much more than a sum of the
            lines of code they wrote.
            <br />
            <br />
            Swarmia makes it easy to track performance in terms of business
            outcomes: did the code we wrote actually solve the business problems
            we set out to solve?
          </>
        }
        image={getImage(data.mosaicLeft1)!}
        quote={quotes.walter}
      />
      <QuoteMosaicBlock
        title={<>Activity</>}
        content={
          <>
            Not all activity is created equal. That’s why Swarmia focuses on
            visualizing work and allowing you to quickly identify concrete areas
            of improvement.
            <br />
            <br />
            Instead of using data to stack rank engineers, we encourage using it
            to identify and fix the systemic bottlenecks that might be slowing
            down your teams.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        quote={quotes.silvia}
      />
      <QuoteMosaicBlock
        title={<>Communication & collaboration</>}
        content={
          <>
            A lot of the producivity bottlenecks in developers’ daily work have
            to do with communication and collaboration.
            <br />
            <br />
            Swarmia helps engineering teams automate the tedious part of
            communication like code review requests, status updates, and issue
            completion recaps.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
        quote={quotes.josep}
      />
      <QuoteMosaicBlock
        title={<>Efficiency & flow</>}
        content={
          <>
            Efficiency and flow come down to the ability for individuals and
            teams to complete the work they’ve started with limited
            interruptions and few competing priorities.
            <br />
            <br />
            In Swarmia, you can measure this particular area with a combination
            of DORA metrics, surveys, and intuitive data visualizations.
          </>
        }
        image={getImage(data.mosaicRight3)!}
        imageAlign="right"
        quote={quotes.tim}
      />
      <CTABlock title="Start measuring the five elements of the SPACE framework today." />
      <LeadBlock
        heading="See Swarmia in action"
        content="Watch our product overview to understand how some of the best software teams in the world use Swarmia to ship better software faster."
      />
      <VideoBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        thumbnail={getImage(data.videoThumbnail)!}
        videoId="ZHcQO20bL4w"
      />
      <FeaturedBlogArticles
        heading="More from the swarmia blog"
        articles={[
          '/blog/space-framework/',
          '/blog/dora-metrics/',
          '/blog/measuring-software-development-productivity/',
        ]}
      />
      <CTABlock title={<>Ready to start improving SPACE metrics?</>} />
    </Layout>
  )
}

export default SPACEPage
